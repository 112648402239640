import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

enum PrincipalRoutesEnum {
    DatatableSendNotificationCenter = '/principal/central-de-notificacoes/enviar',
    DatatableSentNotificationCenter = '/principal/central-de-notificacoes/enviadas',
}

export function PrincipalRouter() {
    /*Rotas para Dashboard*/
    const DatatableSendNotificationCenter = lazy(
        () => import('../../pages/principal/notification-center/DatatableSendNotificationCenter')
    )
    const DatatableSentNotificationCenter = lazy(
        () => import('../../pages/principal/notification-center/DatatableSentNotificationCenter')
    )

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route
                path={PrincipalRoutesEnum.DatatableSendNotificationCenter}
                component={DatatableSendNotificationCenter}
            />
            <Route
                path={PrincipalRoutesEnum.DatatableSentNotificationCenter}
                component={DatatableSentNotificationCenter}
            />
        </Switch>
    )
}
